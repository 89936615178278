<template>
  <div class="container col-between-center">
    <nav-bar :show-back="true" header-title="转赠详情" :header-back="appBack" />
    <div class="v-box col-center-center">
      <img class="avator" :src="`${isReceiver ? tableData.givingAvatar : 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/gift-receive/avator.png'}`" alt="">
      <div class="has col-start-center" v-if="isReceiver">
        <div class="title">
          <p class="p1 f36 fw6 row-start-center"><span class="ellipsis">{{ tableData.givingUserName }}</span>送了</p>
          <p class="p2 f24 row-start-center"><span class="ellipsis">{{ tableData.receiveUserName }}</span>（{{ tableData.receiveUserPhone }}）的优惠券红包</p>
        </div>
        <img class="img1" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/gift-receive/voucher-receive-card-1.png" alt="">
        <div class="card row-between-center">
          <div class="c-left flex-auto">
            <p class="p3 f28 fw6 ellipsis">{{ tableData.name }}</p>
            <p class="p4 f20 row-center-center" v-if="tableData.customTags">{{ tableData.customTags }}</p>
            <p class="p5 f20">{{ tableData.startTime }}-{{ tableData.endTime }}</p>
          </div>
          <div class="c-right col-start-center">
            <p class="p6 f72 BebasNeueBold" v-if="[2, 3].includes(tableData.styleType)">免单</p>
            <p class="p6 f72 BebasNeueBold" v-else><span class="f32">¥</span>{{ tableData.discount }}</p>
            <p class="p7 f20 fw6" v-if="[2, 3].includes(tableData.styleType)">最多优惠{{ tableData.discount }}元</p>
            <p class="p7 f20 fw6" v-else>满{{ tableData.spend }}元可用</p>
          </div>
          <div class="tag f18 row-center-center">共{{ tableData.givingCount }}张</div>
        </div>
        <p class="tip f24">受赠方可到 <span class="fw6">我的＞优惠券/课包券</span> 查看</p>
      </div>
      <div v-else class="empty f40 puhui">
        你不是优惠券红包受赠方哦<br />无法查看页面!
      </div>
    </div>
    <div v-if="isReceiver" class="time f24">赠送时间：{{ tableData.createDate }}</div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar/nav-bar'
export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      tableData: {

      },
      isReceiver: false
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    const { givingId } = getParam()
    this.getData(givingId)
  },
  methods: {
    appBack: newAppBack,
    getData(id) {
      this.$axios.post(`${this.baseURLApp}/giving/queryGivingUserVoucherDetail`, {
        userId: this.userId,
        token: this.token,
        givingId: id
      }).then(res => {
        this.isReceiver = true
        this.tableData = res.data
      }).catch(() => {
        this.$toast.clear()
      })
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  box-sizing: border-box;
  height: 100vh;
  padding: calc(184px + constant(safe-area-inset-top)) 0 calc(84px + constant(safe-area-inset-bottom));
  padding: calc(184px + env(safe-area-inset-top)) 0 calc(84px + env(safe-area-inset-bottom));
  background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/gift-receive/voucher-receive-bg.png);
  background-size: 100% 100%;
  background-position: center;
  * {
    box-sizing: border-box;
  }

  .p1, .p2 {
    color: #502810;
  }
  .p1 {
    span {
      max-width: 360px;
    }
  }
  .p2 {
    margin: 8px 0 0;
    span {
      max-width: 120px;
    }
  }
  .p3 {
    max-width: 360px;
  }
  .p4 {
    padding: 0 16px;
    height: 32px;
    background: #FF6C09;
    border-radius: 16px;
    color: #fff;
    margin: 12px 0 0;
    width: fit-content;
  }
  .p6, .p7 {
    color: #fff;
  }
  .p5 {
    margin: 16px 0 0;
  }
  .tip {
    color: #3C454E;
    margin: 48px 0 0;
  }
  .v-box {
    width: 686px;
    height: 708px;
    padding: 40px 0 0;
    background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/gift-receive/voucher-receive-card.png);
    background-size: 100% auto;
    position: relative;
    .avator {
      width: 144px;
      height: 144px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 38px;
      background-color: #3C454E;
    }
    .has {
      .title {
        width: 100%;
        padding: 0 0 0 180px;
      }
      .img1 {
        width: 638px;
        margin: 24px 0 26px;
      }
      .tip {
        margin: 52px 0 0;
      }
    }
    .empty {
      text-align: center;
      line-height: 72px;
    }
  }
  .card {
    width: 630px;
    height: 182px;
    background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/gift-receive/voucher-receive-vouc.png);
    background-size: 100% 100%;
    // padding: 0 28px 0 28px;
    position: relative;
    overflow: hidden;
    .c-left {
      padding: 0 0 0 28px;
    }
    .c-right {
      width: 240px;
      margin: 20px 0 0;
    }
    .tag {
      padding: 0 20px;
      height: 32px;
      color: #FFFFFF;
      position: absolute;
      right: 0;
      top: 0;
      background: #FF5948;
      border-radius: 0px 8px 0px 8px;
    }
  }
  .time {
    color: #3C454E;
  }
}
</style>
